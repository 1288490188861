<template>
	<div style="height: 100%;">
		<template v-if="current_shop">
			<wochat-users v-if="current_shop.oa_type == 'App\\Models\\WochatApp'"></wochat-users>
			<dingtalk-users v-if="current_shop.oa_type == 'App\\Models\\DingtalkApp'"></dingtalk-users>
		</template>
		<el-tabs :tab-position="theme.width > 980 ? 'left' : 'top'" :style="{height: (theme.height - 160)+'px'}" v-model="active_tab" v-else>
			<!-- <el-tab-pane label="钉钉" name="dingtalk">
				<dingtalk-users></dingtalk-users>
			</el-tab-pane> -->
			<el-tab-pane label="企业微信" name="wochat">
				<wochat-users></wochat-users>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>


<style>
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import wochatUsers from './oa_wochat';
	import dingtalkUsers from './oa_dingtalk';

	export default {
		computed: {
			...mapState(['theme', 'current_shop'])
		},
		components: {
			wochatUsers,
			dingtalkUsers
		},
		methods: {

		},
		data() {
			return {
				active_tab: 'wochat'
			}
		},
		created () {

		}
	};
</script>