<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
			</div>
			<div class="wrap">
				<div class="item" v-for="user in users" :key="user.id">
					<van-image width="80px" height="80px" fit="cover" :src="user.avatar"></van-image>
					<div class="desc">
						<div style="font-size: 16px; color: #333;">{{user.name}} @{{user.user ? user.user.name : '未授权注册'}}</div>
						<div style="font-size: 14px; color: #666;" v-if="admin_user.shop_id"><i class="el-icon-office-building"></i> {{user | format_department}} </div>
						<div style="font-size: 14px; color: #666;" v-else><i class="el-icon-office-building"></i> {{user.wechat_app.name}}</div>
						<div style="font-size: 14px; color: #666;">
							<span v-if="user.position"><i class="el-icon-suitcase"></i> {{user.position}}</span>
							<span v-if="user.mobile"><i class="el-icon-mobile"></i> {{user.mobile}}</span>
							<span v-if="user.email"><i class="el-icon-message"></i> {{user.email}}</span>
						</div>
						<div style="font-size: 12px; color: #999;" v-if="user.created_at">创建于 {{user.created_at}}</div>
					</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getApprovals({ ...params, page: v }) }"></van-pagination>
		</template>
		<div class="pc-main" v-else>
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="请输入姓名/手机/邮箱/关键词" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="admin_user.shop_id">
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item v-else>
					<el-select v-model="params.wechat_app_id" placeholder="所属公司" filterable clearable>
						<el-option v-for="app in apps" :key="app.id" :label="app.name+(app.shop ? '@'+app.shop.name : '')" :value="app.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getUsers({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="main-table">
				<el-table :data="users" class="scroll-wrapper absolute" height="100%" :size="theme.size">
					<el-table-column label="头像" width="80">
						<el-image slot-scope="scope" style="width: 50px; height: 50px; line-height: 50px; text-align: center; font-size: 30px;" :src="scope.row.avatar">
							<i slot="error" class="el-icon-picture-outline"></i>
						</el-image>
					</el-table-column>
					<el-table-column label="姓名" prop="name"></el-table-column>
					<el-table-column label="员工档案" :formatter="(r) => { return r.employee ? r.employee.name : '未授权创建'; }"></el-table-column>
					<el-table-column label="所属部门" v-if="admin_user.shop_id" min-width="120">
						<template slot-scope="scope">{{scope.row | format_department}}</template>
					</el-table-column>
					<el-table-column label="所属公司" prop="wechat_app.name" v-else></el-table-column>
					<el-table-column label="当前职位" prop="position"></el-table-column>
					<el-table-column label="手机号码" prop="mobile"></el-table-column>
					<el-table-column label="电子邮箱" prop="email"></el-table-column>
					<el-table-column label="维护时间" min-width="140">
						<template slot-scope="scope">
							<template v-if="scope.row.deleted_at">已离职</template>
							<template v-else>
								<p v-if="scope.row.updated_at">更新于 {{scope.row.updated_at}}</p>
								<p v-if="scope.row.created_at">注册于 {{scope.row.created_at}}</p>
							</template>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<main-footer>
				<el-pagination
					@size-change="(v) => { getUsers({ ...params, page: 1, perPage: v}) }"
					@current-change="(v) => { getUsers({ ...params, page: v}) }"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					background>
				</el-pagination>
			</main-footer>
		</div>
	</div>
</template>

<style>
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
	.mobile .item {
		display: flex; margin-bottom: 10px; padding: 10px; background-color: #fff;
	}
	.mobile .desc {
		margin-left: 10px; flex: 1; display: flex; flex-direction: column; justify-content: space-between;
	}
	.mobile .desc span {
		margin-right: 10px;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainFooter from '@/components/table-footer';

	var _self_departments = [];

	const _tree_departments = (departments, id) => {
		if (!_self_departments[id]) return departments;
		departments.unshift(_self_departments[id].name);
		if (_self_departments[id].parent_id) return _tree_departments(departments, _self_departments[id].parent_id);
		return departments;
	}

	export default {
		components: {
			mainFooter
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		filters: {
			format_department ({main_department}) {
				if (!main_department) return '未知部门';
				return _tree_departments([], main_department).join(' / ');
			}
		},
		methods: {
			async handleWochatUser (params) {
				const res = await this.$http.get(this.$api.URI_WOCHAT_USERS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getUsers(this.params);
					}
				});
			},
			async getUsers (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_WOCHAT_USERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.users = result.data;
				this.apps = result.apps;
				for (let d of result.departments) {
					_self_departments[d.id] = d;
				}
				this.tree_departments = this.$utils.buildTree(result.departments);
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				apps: [],
				users: [],
				departments: [],
				params: {
					perPage: 10
				}
			}
		},
		created () {
			this.getUsers(this.params, true);
		}
	};
</script>